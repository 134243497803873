// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-award-js": () => import("./../../../src/pages/award.js" /* webpackChunkName: "component---src-pages-award-js" */),
  "component---src-pages-bio-about-js": () => import("./../../../src/pages/bio/about.js" /* webpackChunkName: "component---src-pages-bio-about-js" */),
  "component---src-pages-bio-broadcaster-js": () => import("./../../../src/pages/bio/broadcaster.js" /* webpackChunkName: "component---src-pages-bio-broadcaster-js" */),
  "component---src-pages-bio-businessman-js": () => import("./../../../src/pages/bio/businessman.js" /* webpackChunkName: "component---src-pages-bio-businessman-js" */),
  "component---src-pages-bio-christian-js": () => import("./../../../src/pages/bio/christian.js" /* webpackChunkName: "component---src-pages-bio-christian-js" */),
  "component---src-pages-bio-educator-js": () => import("./../../../src/pages/bio/educator.js" /* webpackChunkName: "component---src-pages-bio-educator-js" */),
  "component---src-pages-bio-statesman-js": () => import("./../../../src/pages/bio/statesman.js" /* webpackChunkName: "component---src-pages-bio-statesman-js" */),
  "component---src-pages-collection-js": () => import("./../../../src/pages/collection.js" /* webpackChunkName: "component---src-pages-collection-js" */),
  "component---src-pages-ellen-armstrong-js": () => import("./../../../src/pages/ellen-armstrong.js" /* webpackChunkName: "component---src-pages-ellen-armstrong-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

